import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { endOfDay, sub, startOfDay } from "date-fns";
import { debounce, mean, round } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { MEASURE_TYPES } from "../../utils/constants";
import { get, post } from "../../utils/io";
import theme from "../../utils/theme";
import { useModal } from "../hooks";
import HubAutoComplete from "../HubAutocomplete";
import MainContentContainer from "../Layouts/MainContentContainer";
import MeasureList from "../MeasureList";
import MeasureLineChart from "../Measures/MeasureLineChart";
import MeasureSearchForm from "../Measures/MeasureSearchForm";
import { MeasureToolbarButton } from "../Measures/MeasureToolbar";
import StelModal from "../StelModal";

const TABS = [
  { index: 0, label: "Chart" },
  { index: 1, label: "Raw Data" },
];

const DEFAULT_PAGINATION = {
  pageSize: 20,
  page: 0,
};

const getEndOfDay = () => endOfDay(new Date());
const getSevenDaysAgo = () => sub(getEndOfDay(), { days: 7 });

const EMPTY_SEARCH_INPUTS = {
  macAddress: "",
  takenAfterDate: getSevenDaysAgo(),
  takenBeforeDate: getEndOfDay(),
};

const ORDER_STATUS_STYLES = {
  default: { fontWeight: "bold", textTransform: "capitalize" },
  active: { color: "#1F79E2", background: "#CEE5FF" },
  preorder: { color: "#A6A6A6" },
  completed: { color: "#2E8F40", background: "#E0FFE5" },
  canceled: { color: "#FF5959", background: "#FF595914" },
};

// TODO: Leaving this commented out in case we need it later
// const csvHeaders = {
//   timestamp: ["Date", "Time"],
//   systolic: "Systolic",
//   diastolic: "Diastolic",
//   bpm: "Heart Rate",
// };

function CopyModal({ text, open, onClose }) {
  const textInputRef = useRef(null);

  const selectTextInput = () => {
    const input = textInputRef.current;
    if (input) {
      input.focus();
      input.select();
    }
  };

  return (
    <StelModal
      open={open}
      onClose={onClose}
      title="Copy to Clipboard"
      width={500}
    >
      <TextField
        inputRef={textInputRef}
        value={text}
        fullWidth
        multiline
        maxRows={10}
      />
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: "10px" }}>
        <Button onClick={selectTextInput} variant="contained">
          Select All
        </Button>
      </Stack>
    </StelModal>
  );
}

CopyModal.propTypes = {
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function XealthApp() {
  const { xpid } = useParams();
  const [loading, setLoading] = useState(false);
  const [xealthOrder, setXealthOrder] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [totalCount, setTotalCount] = useState(0);
  const [searchInputs, setSearchInputs] = useState({ ...EMPTY_SEARCH_INPUTS });
  const [autoRefreshMeasure, setAutoRefreshMeasure] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [swapHub, setSwapHub] = useState();

  const [isModalSwap, setIsModalSwap] = useState(false);
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalConfirmReactive, setIsModalConfirmReactive] = useState(false);
  const {
    isModal: isModalOpen,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
  } = useModal();

  const isFirstLoad = useRef(true);

  const getMeasureList = useCallback(async (searchCriteria) => {
    const res = await get("/xealth/measures", {
      xealth_patient_id: searchCriteria.xpid,
      device_mac: searchCriteria.macAddress,
      taken_after: searchCriteria.takenAfterDate.toISOString(),
      taken_before: searchCriteria.takenBeforeDate.toISOString(),
    });

    if (res?.status === 200) {
      setTotalCount(res.data.total_count);
      setSearchResults(res.data.measures);
    } else {
      enqueueSnackbar("Unable to retrieve measurements", {
        variant: "error",
      });
    }
  }, []);

  const updateMeasureGrid = useCallback(() => {
    const debouncedGetMeasureGrid = debounce(async () => {
      setLoading(true);
      await getMeasureList({ xpid, ...searchInputs, ...pagination });
      setLoading(false);
    }, 300);
    debouncedGetMeasureGrid();
    return debouncedGetMeasureGrid.cancel;
  }, [xpid, searchInputs, pagination, getMeasureList]);

  const fetchXealthOrder = useCallback(async () => {
    const res = await get(`/xealth/order?xealth_patient_id=${xpid}`);
    if (res?.status === 200) {
      setXealthOrder(res.data);
    }
  }, [xpid]);

  const handleCompleteProgram = async () => {
    const res = await post("/xealth/complete_program", {
      xealth_patient_id: xpid,
    });

    if (res?.status === 204) {
      enqueueSnackbar("Completed monitoring", { variant: "success" });
      fetchXealthOrder();
    } else {
      enqueueSnackbar(
        "Failed to complete monitoring. Contact support@stel.life",
        { variant: "error" }
      );
    }
  };

  const handleSwapHub = async () => {
    const res = await post("xealth/order/swap_hub", {
      xealth_patient_id: xpid,
      hub_id: swapHub,
    });

    if (res?.status === 204) {
      enqueueSnackbar("Completed Hub Swap", { variant: "success" });
      setIsModalConfirm(false);
      fetchXealthOrder();
    } else {
      enqueueSnackbar("Failed to Swap Hub. Contact support@stel.life", {
        variant: "error",
      });
    }
  };

  const handleReactive = async () => {
    const res = await post("xealth/order/reactivate", {
      xealth_patient_id: xpid,
    });

    if (res?.status === 204) {
      enqueueSnackbar("Successfully reactivated", { variant: "success" });
      setIsModalConfirmReactive(false);
      fetchXealthOrder();
    } else {
      enqueueSnackbar("Failed to reactivate. Contact support@stel.life", {
        variant: "error",
      });
    }
  };

  const toggleModalSwapHub = () => setIsModalSwap((prev) => !prev);

  const toggleModalReactive = () => setIsModalConfirmReactive((prev) => !prev);

  const handleModalSwapHubConfirm = () => {
    setIsModalConfirm((prev) => !prev);
    if (isModalSwap) setIsModalSwap(false);
    if (isModalConfirm) setSwapHub(undefined);
  };

  const handleTabChange = (_, newValue) => setTabIndex(newValue);

  const onSelectionChanged = (selectionModel) =>
    setSelectedMeasures(selectionModel);

  const onChangePage = (page) => setPagination((prev) => ({ ...prev, page }));

  const onChangeRowPerPage = (pageSize) =>
    setPagination((prev) => ({ ...prev, pageSize }));

  const refreshMeasureList = () => setPagination({ ...pagination });

  useEffect(() => {
    fetchXealthOrder();
  }, [fetchXealthOrder]);

  useEffect(() => {
    if (isFirstLoad.current) {
      getMeasureList({ xpid, ...searchInputs, ...pagination });
      isFirstLoad.current = false;
    } else {
      updateMeasureGrid();
    }
  }, [xpid, searchInputs, pagination, getMeasureList, updateMeasureGrid]);

  useEffect(() => {
    if (autoRefreshMeasure) {
      const interval = setInterval(updateMeasureGrid, 10000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [autoRefreshMeasure, updateMeasureGrid]);

  const getOrderStatusStyle = (status) => {
    if (!status) return {};
    const baseStyle = ORDER_STATUS_STYLES.default;
    const statusStyle = ORDER_STATUS_STYLES[status];
    return { ...baseStyle, ...statusStyle };
  };

  const formatMeasureValue = (type, value) => {
    if (type === MEASURE_TYPES.bloodpressure.value) {
      return `${value.systolic}/${value.diastolic}, ${value.bpm} bpm`;
    }
    return JSON.stringify(value);
  };

  const formatMeasuresToCopy = () =>
    searchResults
      .map((measure) => {
        const timestamp = new Date(measure.timestamp).toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        return `${timestamp}, ${formatMeasureValue(
          measure.type,
          measure.value
        )}`;
      })
      .join("\n");

  const computeAverageLastThreeMeasures = (measures) => {
    if (measures.length === 0) {
      return { bp: "No data", heartRate: "No data" };
    }

    const bpMeasures = measures
      .filter(({ type }) => type === MEASURE_TYPES.bloodpressure.value)
      .slice(0, 3);

    if (bpMeasures.length === 0) {
      return { bp: "No data", heartRate: "No data" };
    }

    const avgSys = round(mean(bpMeasures.map((m) => m.value.systolic)));
    const avgDia = round(mean(bpMeasures.map((m) => m.value.diastolic)));
    const avgHeartRate = round(mean(bpMeasures.map((m) => m.value.bpm)));

    return {
      bp: `${avgSys}/${avgDia} mmHg`,
      heartRate: `${avgHeartRate} bpm`,
    };
  };

  // TODO: Leaving this commented out in case we need it later
  // const getCSVData = () => {
  //   const keys = Object.keys(csvHeaders);
  //   const headers = Object.values(csvHeaders).flat();
  //   const rows = chartData
  //     .map((measure) => {
  //       const formattedMeasure = keys.flatMap((key) => {
  //         if (key === "timestamp") {
  //           const dateObject = new Date(measure[key]);
  //           const localDate = dateObject.toLocaleDateString(
  //             navigator.language,
  //             {
  //               month: "2-digit",
  //               day: "2-digit",
  //               year: "numeric",
  //             }
  //           );
  //           const localTime = dateObject.toLocaleTimeString(
  //             navigator.language,
  //             {
  //               hour12: false,
  //               hour: "2-digit",
  //               minute: "2-digit",
  //               second: "2-digit",
  //             }
  //           );

  //           return [localDate, localTime];
  //         }
  //         return measure[key];
  //       });
  //       return formattedMeasure.join(",");
  //     })
  //     .reverse()
  //     .join("\n");
  //   return `${headers.join(",")}\n${rows}`;
  // };

  const getTicks = (startDate, endDate) => {
    const ticks = [];
    let currDate = startDate;
    while (currDate < endDate) {
      ticks.push(currDate);
      const tempDate = new Date(currDate);
      currDate = startOfDay(tempDate.setDate(tempDate.getDate() + 1)).valueOf();
    }
    return ticks;
  };

  const chartData = searchResults
    .filter((measure) => measure.type === MEASURE_TYPES.bloodpressure.value)
    .map((measure) => ({ ...measure.value, timestamp: measure.timestamp }))
    .reverse();

  const averageLastThreeMeasures =
    computeAverageLastThreeMeasures(searchResults);

  const isActiveOrder = xealthOrder.status === "active";
  const isCompletedOrCanceled =
    xealthOrder.status === "completed" || xealthOrder.status === "canceled";
  const noMeasurementsFound = chartData.length === 0;

  return (
    <MainContentContainer
      title={
        <Grid container alignItems="center">
          <Box
            component="img"
            src="/stel.png"
            alt=""
            sx={{ height: 40, width: 40 }}
          />
          <Typography
            sx={{
              fontWeight: 400,
              color: theme.colors.grey,
              fontSize: 24,
              overflow: "hidden",
            }}
          >
            Stel
          </Typography>
        </Grid>
      }
    >
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <MeasureSearchForm
          onSubmit={setSearchInputs}
          isSearchDisabled={loading}
          searchCriterias={{
            hubId: false,
            macAddress: true,
            status: false,
          }}
          defaultSearchInputs={EMPTY_SEARCH_INPUTS}
        />

        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              mb: "20px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="p2" sx={{ fontWeight: "bold" }}>
              Order Status
            </Typography>
            {xealthOrder?.status && (
              <Chip
                sx={getOrderStatusStyle(xealthOrder.status)}
                label={xealthOrder.status}
              />
            )}
          </Box>
          {isActiveOrder && (
            <Stack sx={{ flexDirection: "row", mb: "30px", gap: 2 }}>
              <Button variant="outlined" onClick={toggleModalSwapHub}>
                Swap Hub
              </Button>
              <Button variant="contained" onClick={handleCompleteProgram}>
                Complete Program
              </Button>
            </Stack>
          )}

          {isCompletedOrCanceled && (
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                mb: "30px",
              }}
            >
              <Button variant="contained" onClick={toggleModalReactive}>
                Reactivate
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>
      <StelModal
        open={isModalSwap}
        onClose={toggleModalSwapHub}
        title="Swap Hub"
        width={400}
      >
        <Stack gap="10px">
          <TextField
            value={xealthOrder.hub_id || ""}
            size="small"
            disabled
            placeholder="Current hub"
          />
          <HubAutoComplete
            filterOptions={(options) =>
              options.filter((option) => option.hub_id !== xealthOrder.hub_id)
            }
            onChange={(_, swapHub) => {
              if (swapHub) setSwapHub(swapHub.hub_id);
            }}
            size="small"
            placeholder="Select a new hub"
          />
          <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleModalSwapHub}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalSwapHubConfirm}
              disabled={!swapHub}
            >
              Swap
            </Button>
          </Stack>
        </Stack>
      </StelModal>

      <StelModal
        open={isModalConfirm || isModalConfirmReactive}
        onClose={
          isModalConfirm ? handleModalSwapHubConfirm : toggleModalReactive
        }
        title={isModalConfirm ? "Swap Hub" : "Reactivate"}
        width={450}
      >
        <Stack gap="10px">
          <Typography sx={{ fontSize: "16px", color: "#00000099" }}>
            {isModalConfirm ? (
              <>
                Are you sure you want to switch to hub{" "}
                <Box
                  component="span"
                  sx={{ color: "#1F79E2", fontWeight: "bold" }}
                >
                  {swapHub}
                </Box>
              </>
            ) : (
              "Are you sure you want to reactivate?"
            )}
          </Typography>
          <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={
                isModalConfirm ? handleModalSwapHubConfirm : toggleModalReactive
              }
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={isModalConfirm ? handleSwapHub : handleReactive}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </StelModal>

      <Stack
        direction="row"
        spacing={5}
        alignItems="center"
        sx={{
          mt: 4,
          mb: 2,
          p: 1,
          border: `1px solid ${grey[400]}`,
          borderRadius: 1,
          width: "fit-content",
        }}
      >
        <Typography variant="p2" sx={{ fontWeight: "bold" }}>
          Average of Last 3 Measurements
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Blood Pressure</Typography>
          <Chip label={averageLastThreeMeasures.bp} color="primary" />
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Heart Rate</Typography>
            <Chip label={averageLastThreeMeasures.heartRate} color="primary" />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ border: `1px solid ${grey[400]}`, borderRadius: 1 }}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          {TABS.map((tab) => (
            <Tab key={tab.index} label={tab.label} />
          ))}
        </Tabs>
        <TabPanel tabIndex={tabIndex} index={0}>
          {noMeasurementsFound ? (
            <Stack flexDirection="row" justifyContent="center">
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography sx={{ color: grey[500] }}>
                  No Measurements Found
                </Typography>
              )}
            </Stack>
          ) : (
            <MeasureLineChart
              data={chartData}
              isShowExportCSVButton={false}
              graphs={[
                {
                  label: "Blood Pressure",
                  value: [
                    {
                      key: "systolic",
                      color: "#8884d8",
                      bounds: [{ color: red[500], value: 180 }, 135],
                    },
                    { key: "diastolic", color: "#82ca9d", bounds: [85] },
                  ],
                },
                {
                  label: "Heart Rate",
                  value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
                },
              ]}
              lineChartProps={{
                margin: { left: -20 },
              }}
              labelProps={{ sx: { mt: "10px" } }}
              XAxisProps={{
                ticks:
                  chartData.length > 0
                    ? getTicks(
                        new Date(chartData[0].timestamp).valueOf(),
                        endOfDay(
                          new Date(chartData[chartData.length - 1].timestamp)
                        ).valueOf()
                      )
                    : [],
                padding: { left: 50, right: 30 },
              }}
            />
          )}
        </TabPanel>
        <TabPanel tabIndex={tabIndex} index={1}>
          <MeasureList
            loading={loading}
            rows={searchResults}
            onSelectionChanged={onSelectionChanged}
            pageSize={pagination.pageSize}
            onPageSizeChange={onChangeRowPerPage}
            rowsPerPageOptions={[20, 25, 50, 100]}
            rowCount={totalCount}
            onPageChange={onChangePage}
            refreshMeasureList={refreshMeasureList}
            selectedMeasures={selectedMeasures}
            searchResults={searchResults}
            setAutoRefreshMeasure={setAutoRefreshMeasure}
            autoRefreshMeasure={autoRefreshMeasure}
            hideColumns={["type", "delivery_status", "transmitting_hub_id"]}
            columnOrder={[
              "timestamp",
              "value",
              "hub_id",
              "device_make",
              "device_model",
              "device_mac",
            ]}
            columnProps={{
              value: {
                valueGetter: (params) =>
                  formatMeasureValue(params.row.type, params.row.value),
              },
            }}
            showDeliveries={false}
            toolbarProps={{
              customComponents: [
                {
                  name: "copyButton",
                  value: (
                    <>
                      <MeasureToolbarButton
                        text="Copy"
                        onClick={handleOpenModal}
                      />
                      <CopyModal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        text={formatMeasuresToCopy()}
                      />
                    </>
                  ),
                },
              ],
            }}
          />
        </TabPanel>
      </Stack>
    </MainContentContainer>
  );
}
function TabPanel({ children, tabIndex, index, ...other }) {
  return (
    <Box hidden={tabIndex !== index} id={`tab-panel-${index}`} {...other}>
      {tabIndex === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default XealthApp;
